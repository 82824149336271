<template>
  <div>
    <b-modal v-model="showModal" hide-header hide-footer centered size="lg">
      <div class="w-100 pt-0 pb-1 px-1">
        <b-row class="no-gutters mt-0">
          <b-col>
            <label class="main">ตั้งค่า</label>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <InputText
              textFloat="ชื่อภาษาไทย"
              placeholder="ชื่อภาษาไทย"
              type="text"
              class="w-100"
              v-model="formConfig.nameTh"
              isRequired
              :isValidate="$v.formConfig.nameTh.$error"
              :v="$v.formConfig.nameTh"
            />
          </b-col>
          <b-col cols="6">
            <InputText
              textFloat="ชื่อภาษาอังกฤษ"
              placeholder="ชื่อภาษาอังกฤษ"
              type="text"
              class="w-100"
              v-model="formConfig.nameEng"
              isRequired
              :isValidate="$v.formConfig.nameEng.$error"
              :v="$v.formConfig.nameEng"
            />
          </b-col>
          <b-col cols="12">
            <InputText
              textFloat="URL"
              placeholder="URL"
              type="text"
              class="w-100"
              v-model="formConfig.url"
              isRequired
              :isValidate="$v.formConfig.url.$error"
              :v="$v.formConfig.url"
            />
          </b-col>
          <b-col cols="12">
            <InputText
              textFloat="Api Key"
              placeholder="Api Key"
              type="text"
              class="w-100"
              v-model="formConfig.apiKey"
              isRequired
              :isValidate="$v.formConfig.apiKey.$error"
              :v="$v.formConfig.apiKey"
            />
          </b-col>
          <b-col cols="12">
            <InputText
              textFloat="Secret Key"
              placeholder="Secret Key"
              type="text"
              class="w-100"
              v-model="formConfig.secretKey"
              isRequired
              :isValidate="$v.formConfig.secretKey.$error"
              :v="$v.formConfig.secretKey"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between align-items-center mt-2">
          <b-button class="button btn-cancel" @click="showModal = false"
            >ย้อนกลับ</b-button
          >
          <b-button class="btn-main" @click="submit">บันทึก</b-button>
        </div>
      </div>
    </b-modal>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    formConfig: {
      required: false,
      type: [Object, Array],
    },
  },
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      showModal: false,
      isBusy: false,
      modalMessage: "",
    };
  },
  validations() {
    return {
      formConfig: {
        nameTh: {
          required,
        },
        nameEng: {
          required,
        },
        url: {
          required,
        },
        apiKey: {
          required,
        },
        secretKey: {
          required,
        },
      },
    };
  },
  methods: {
    show() {
      this.$v.formConfig.$reset();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    submit: async function () {
      this.$v.formConfig.$touch();
      if (this.$v.formConfig.$error) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 450
            );
          }
        });
        return;
      }
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/shipping/ShippingConfig/${this.formConfig.id}`,
        null,
        this.$headers,
        this.formConfig
      );

      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          this.hide();
        }, 2000);
      } else {
        this.modalMessage = data.message;
        this.$refs.modalAlertError.show();
      }
      this.isBusy = false;
    },
  },
};
</script>

<style scoped>
::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.main {
  font-size: 17px;
  margin-bottom: 0;
}

label {
  color: #16274a;
  font-size: 16px;
  font-weight: bold;
}

::v-deep .input-custom > label {
  font-weight: normal !important;
}

.modal-body {
  margin: auto;
  text-align: center;
}

::v-deep .panel-input-serach {
  max-width: 100% !important;
  border: none !important;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
