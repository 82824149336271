<template>
  <div v-if="$isLoading">
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">ตั้งค่าการจัดส่ง</h1>
        </b-col>
        <!-- <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-button variant="main" @click="addNewConditon()"
              >เพิ่มเงื่อนไข</b-button
            >
          </div>
        </b-col> -->
      </CRow>
      <b-container class="container-box bg-white px-3 pb-3 pt-1 mt-4">
        <div class="mx-4">
          <label class="mt-3 main">การจัดส่งสินค้าภายในประเทศ</label>
          <hr />
        </div>
        <b-row class="no-gutters">
          <label class="ml-4 mt-1">ตั้งค่าเริ่มต้น</label>
          <b-row class="mx-2 w-100">
            <b-col cols="3" class="d-flex align-items-end">
              <InputText
                textFloat="ขนาดของพัสดุ"
                placeholder="ความกว้าง"
                type="number"
                class="w-100"
                v-model="form.width"
            /></b-col>
            <b-col cols="3" class="d-flex align-items-end">
              <InputText
                placeholder="ความยาว"
                class="w-100"
                v-model="form.length"
                type="number"
            /></b-col>
            <b-col cols="3" class="d-flex align-items-end">
              <InputText
                placeholder="ความสูง"
                class="w-100"
                v-model="form.height"
                type="number"
            /></b-col>
            <b-col cols="3" class="d-flex align-items-end">
              <InputText
                placeholder="น้ำหนัก"
                class="w-100"
                v-model="form.weight"
                type="number"
            /></b-col>
          </b-row>
        </b-row>
        <b-row class="mx-2 mt-1">
          <b-col
            cols="6"
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-flex justify-content-center align-items-center">
              <label class="font-weight-bold main-label">
                การเพิ่มค่าพื้นที่ห่างไกล
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  @change="updateRemoteEnable"
                  v-model="form.shippingStatus"
                >
                  <span class="ml-2 mt-1 main-label">
                    {{ form.shippingStatus ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <label class="font-weight-bold main-label">
              การคำนวนพื้นที่ห่างไกล
            </label>
          </b-col>
        </b-row>
        <b-row class="mx-2 mt-1">
          <b-col cols="6" class="d-flex align-items-end">
            <InputText
              textFloat="ค่าขนส่งที่คำนวนเพิ่ม"
              placeholder="ระบุค่าขนส่ง"
              type="number"
              name="endWeight"
              class="w-100"
              v-model="form.shippingPrice"
              isRequired
          /></b-col>
          <b-col cols="6" class="d-flex align-items-start">
            <p>
              เป็นการคำนวนค่าพื้นที่ห่างไกลเพิ่มจากค่าขนส่งปกติ เช่น
              ค่าขนส่งที่ระบบคำนวนได้คือ 40.00 บาท หากปลายทางเป็นพื้นที่ห่างไกล
              จะมีการบวกเพิ่มไปอีก
            </p>
          </b-col>
        </b-row>
        <b-row class="mx-2 mb-2">
          <a
            @click="showModal = true"
            class="text-underline text-primary ml-3 fs-14"
            >ดูรายการพื้นที่ห่างไกล</a
          >
        </b-row>
        <b-row class="mx-2 mb-2">
          <label class="ml-3 mt-3">ขนส่งที่เปิดใช้งาน</label>
          <b-row class="mx-2 w-100">
            <b-col
              cols="3"
              class="px-2"
              v-for="(item, index) in thaiShipping"
              :key="index"
              ><div class="items my-2">
                <div class="header d-flex justify-content-between">
                  <div class="d-flex align-items-start">
                    <img :src="item.imageUrl" />
                    <span class="ml-2 font-weight-bold fs-14">{{
                      item.name
                    }}</span>
                  </div>
                  <div class="pointer" @click="openModalConfig(item)">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div>
                <div class="footer">
                  <div>
                    <b-form-checkbox
                      switch
                      class="radio-active ml-3"
                      size="lg"
                      @change="updateConfig(item)"
                      v-model="item.enabled"
                    >
                      <span class="ml-2 main-label font-weight-normal">
                        {{ item.enabled ? "เปิด" : "ปิด" }}
                      </span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div></b-col
            >
          </b-row>
        </b-row>
      </b-container>
      <b-container class="container-box bg-white px-4 py-2 mt-3">
        <div class="mx-4">
          <div class="d-flex align-items-center mt-2">
            <label class="font-weight-bold main-label">
              การจัดส่งสินค้าต่างประเทศ
            </label>
            <!-- <div>
              <b-form-checkbox
                switch
                class="radio-active ml-3"
                size="lg"
                v-model="form.enabled"
              >
                <span class="ml-2 mt-1 main-label">
                  {{ form.enabled ? "เปิด" : "ปิด" }}
                </span>
              </b-form-checkbox>
            </div> -->
          </div>
          <hr />
        </div>
        <b-row class="mx-2 mb-2">
          <b-col
            cols="3"
            class="px-2"
            v-for="(item, index) in abroadShipping"
            :key="index"
            ><div class="items my-2">
              <div class="header d-flex justify-content-between">
                <div class="d-flex align-items-start">
                  <img :src="item.imageUrl" />
                  <span class="ml-2 font-weight-bold fs-14">{{
                    item.name
                  }}</span>
                </div>
                <div class="pointer" @click="openModalConfig(item)">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
              <div class="footer">
                <div>
                  <b-form-checkbox
                    switch
                    class="radio-active ml-3"
                    size="lg"
                    @change="updateConfig(item)"
                    v-model="item.enabled"
                  >
                    <span class="ml-2 main-label font-weight-normal">
                      {{ item.enabled ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
            </div></b-col
          >
        </b-row>
      </b-container>
      <b-container class="container-box bg-white px-4 py-4 mt-3">
        <b-row class="px-3">
          <!-- <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="form.enabled"
                >
                  <span class="ml-2 main-label">
                    {{ form.enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col> -->
          <b-col class="d-flex justify-content-end align-items-center">
            <!-- <router-link :to="'/shipping-setting/thai'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            > -->
            <b-button class="btn-main ml-3" @click="submit()">บันทึก</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal v-model="showModal" hide-header hide-footer centered size="lg">
      <div class="w-100 p-1">
        <b-row>
          <b-col cols="6">
            <label class="mt-2 main">รายการพื้นที่ห่างไกล</label>
          </b-col>
          <b-col cols="6">
            <div class="search d-flex justify-content-between">
              <b-input-group class="panel-input-serach w-100">
                <b-form-input
                  class="input-serach"
                  placeholder="ค้นหาชื่ออำเภอ หรือรหัสไปรษณีย์"
                  v-model="filterShippingRemote.Search"
                  @keyup="handleSearch"
                ></b-form-input>
                <b-input-group-prepend @click="btnSearch">
                  <span class="icon-input m-auto pr-2">
                    <font-awesome-icon icon="search" title="View" />
                  </span>
                </b-input-group-prepend>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row class="no-gutters table-detail mt-3">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="shippingRemote"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end align-items-center mt-1">
          <b-button class="button btn-cancel" @click="showModal = false"
            >ปิด</b-button
          >
          <!-- <b-button class="btn-main">บันทึก</b-button> -->
        </div>
      </div>
    </b-modal>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalConfig ref="modalConfig" :formConfig="formConfig" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalConfig from "./ModalConfig.vue";
import ModalLoading from "@/components/modal/alert/ModalLoading";

import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "Shipping",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalConfig,
    ModalLoading,
    InputText,
  },
  data() {
    return {
      modalMessage: "",
      id: "",
      items: [{ start_weight: "", weight: "", price: "", id: 0 }],
      isBusy: false,
      thaiShipping: [],
      abroadShipping: [],
      shippingRemote: [],
      form: {},
      filter: {
        PageNo: 1,
        PerPage: 10,
        Type: 1,
      },
      filterShippingRemote: {
        PageNo: 1,
        PerPage: 10,
        search: "",
      },
      formConfig: {},
      isDisable: false,
      showModal: false,
      fields: [
        { key: "district", label: "อำเภอ", class: "w-50px" },
        { key: "province", label: "จังหวัด", class: "w-100px" },
        { key: "zipCode", label: "รหัสไปรษณีย์", class: "w-100px" },
      ],
    };
  },
  validations: {
    form: {
      shippingPrice: { required, minValue: minValue(0) },
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    await this.getShippingRemote();
    this.$isLoading = true;
  },
  methods: {
    getList: async function (filter = this.filter) {
      filter.InCountry = 1;
      let resDataThai = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/shipping/ShippingList`,
        null,
        this.$headers,
        filter
      );
      if (resDataThai.result == 1) {
        this.thaiShipping = resDataThai.detail.dataList;
      }

      filter.InCountry = 0;
      let resDataAbroad = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/shipping/ShippingList`,
        null,
        this.$headers,
        filter
      );
      if (resDataAbroad.result == 1) {
        this.abroadShipping = resDataAbroad.detail.dataList;
      }

      let remoteDisplay = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/ShippingRemoteDisplay`,
        null,
        this.$headers,
        null
      );
      if (remoteDisplay.result == 1) {
        this.form = remoteDisplay.detail;
        this.form.shippingStatus =
          parseInt(this.form.shippingStatus) == 1 ? true : false;
      }
    },
    async getShippingRemote() {
      this.isBusy = true;
      let shippingRemote = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/ShippingRemote`,
        null,
        this.$headers,
        null
      );
      if (shippingRemote.result == 1) {
        this.shippingRemote = shippingRemote.detail;
        this.isBusy = false;
      }
    },
    async openModalConfig(item) {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/ShippingConfig/${item.id}`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.formConfig = resData.detail;
      }
      this.$refs.modalConfig.show();
    },
    async updateConfig(item) {
      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/shipping/ShippingList`,
        null,
        this.$headers,
        {
          id: item.id,
          enabled: item.enabled,
        }
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        setTimeout(() => {
          this.$refs.modalLoading.hide();
        }, 1500);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    submit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 450
            );
          }
        });
        return;
      }

      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/shipping/UpdateShipping`,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
      } else {
        this.modalMessage = data.message;
        this.$refs.modalAlertError.show();
      }
    },
    async updateRemoteEnable() {
      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/ShippingRemoteEnable/${
          this.form.shippingStatus ? 0 : 1
        }`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        setTimeout(() => {
          this.$refs.modalLoading.hide();
        }, 1500);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filterShippingRemote.PageNo = 1;
        this.getShippingRemote();
      }, 800);
    },
    btnSearch() {
      this.filterShippingRemote.PageNo = 1;
      this.getShippingRemote();
    },
  },
};
</script>

<style scoped>
::v-deep .input-custom > label {
  font-weight: normal !important;
}

.dot {
  background-color: #000;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  margin-bottom: 2px;
}

.fs-14 {
  font-size: 14px;
}

.items {
  border: 1px solid #bcbcbc;
  border-radius: 4px;
}

.header {
  display: flex;
  padding: 10px;
}

.header img {
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer {
  margin-top: 5px;
  background: #dbe3f1;
  padding: 5px 0px;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

p {
  font-size: 14px;
  color: #7f7c7c;
}

label {
  color: #16274a;
  font-size: 16px;
  font-weight: bold;
}

.main {
  font-size: 17px;
  margin-bottom: 0;
}

.search {
  border: 1px solid #bcbcbc;
}

.image {
  width: 75px;
  padding-top: 75px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}
</style>
